<template>
	<svg
		fill="none"
		height="32"
		viewBox="0 0 142 32"
		width="142"
		xmlns="http://www.w3.org/2000/svg"
		xmlns:xlink="http://www.w3.org/1999/xlink"
	>
		<clipPath id="a"><path d="m0 0h142v31.2909h-142z" /></clipPath>
		<g clip-path="url(#a)">
			<path
				d="m132.035-.00001834c1.599.02368834 2.952 1.10446834 4.047 2.50948834.227.29018.726.41159.957.12584 2.136-2.63532831 4.961-2.026833 4.958.74175.163 5.61858-8.224 13.81774-10.269 7.23534-.898-2.89445-3.002-4.85466-3.096-7.01327-.084-1.9454 1.456-3.6272783 3.404-3.59914834z"
				fill="#ea534a"
			/>
			<g fill="#4e2096">
				<path
					d="m1.07492 28.1344c0-2.0475.33608-2.5183 1.30879-3.3563.8054-.6707.90608-1.3428.90608-2.4843v-8.023c0-1.7129-.10068-2.2489-1.27473-2.2829-1.242164-.0667-2.01499693-.5375-2.01499693-1.7456-.03405207-1.54417.77135293-2.75228 1.98093693-3.76051.94014-.77284 2.21487-1.30879 3.45703-1.30879 1.67891 0 2.71972.90608 2.75229 2.24892 0 .80541.16878.94013.50338.94013.47081 0 .67068-.57 1.07486-1.10743.80544-1.27473 2.24894-2.34959 4.39714-2.34959 4.5659 0 7.4189 3.72648 7.4189 9.53307 0 5.8066-2.4843 9.8366-7.8882 9.8366-2.887-.034-2.9537-1.3769-3.72649-1.3428-.77135.0666-.77135.6707-.73878 1.4435.03405.5034.20135.8395 1.00677 1.3088 1.1415.6381 1.6789 1.3088 1.6789 2.9196s-.7388 2.6516-3.18905 2.6856c-3.25567 0-7.65431-.4027-7.65431-3.155zm13.25958-13.4609c-.4368-2.2489-1.2422-3.7265-3.0543-3.3904-1.64489.3021-1.91434 1.9469-1.64489 3.8938.3346 2.7523 1.10739 3.5918 2.95369 3.3564 1.7114-.1673 2.1822-1.5442 1.7455-3.8613z"
				/>
				<path
					d="m44.5491 18.4665c0-1.8802.3687-3.4244.3687-5.472 0-1.0067-.1673-.9061-1.3429-.9401-1.2421-.0666-1.2421-1.0749-1.2421-2.58502 0-2.51837.4708-2.28297 3.7931-4.02851 2.1823-1.17405 3.3904-2.04756 4.9346-2.04756.7047 0 1.3087.33608 1.3087 1.30878 0 1.34284.3346 1.94097 1.8462 1.94097 1.9469 0 2.6857 0 2.6857 1.64486 0 .94013-.1007 1.47609-.1673 2.78638-.1007 1.9128-1.0748 1.9128-3.0558 1.9128-2.3155 0-1.8462-.6988-1.9128 1.4835 0 .7047-.0666 1.4094-.0666 2.0476 0 1.0408.604 1.5782 1.5441 1.5782.8736 0 1.8463-.2354 2.585-.2354.6381 0 1.0068.3361.9727 1.7455-.0325 2.9877-1.9794 5.1034-5.5045 5.1034-3.8938 0-6.7468-2.3496-6.7468-6.2434z"
				/>
				<path
					d="m58.2795 21.2529c0-1.2081.0666-1.9469 1.0067-2.7523.7388-.6381.8395-1.3088.9402-2.7863.0325-1.1415.0325-2.317.0325-3.5918 0-1.3769 0-3.6924-.0325-4.96861-.0341-1.04081-.2014-1.24216-1.1415-1.30878-1.0749-.03405-1.8137-.4027-1.8137-1.71149 0-2.71676 10.2053-6.803 10.2053-1.54418 0 .90608-.1007 2.38364-.2695 5.00121-.2013 2.58495-.5033 6.44475-.3686 8.82835.0666.9727.2013 1.3769.8394 1.9469.7048.6041 1.0734 1.2422 1.0734 2.6857 0 2.4843-.8394 3.4244-5.2706 3.4244-3.9279 0-5.2026-.3361-5.2026-3.2231z"
				/>
				<path
					d="m69.6262 15.0096c0-5.84071 3.0203-10.13867 9.6679-10.07057 6.4121.06662 9.3658 4.1958 9.3658 10.07057 0 5.8747-3.0558 9.8691-9.5687 9.8691-6.5128 0-9.4664-3.9278-9.4664-9.8691zm12.0175-.6041c-.9061-2.887-1.7796-4.0285-3.5252-3.5577-1.6108.4027-2.0475 2.1142-1.3754 4.902.6707 2.585 1.8122 3.4911 3.4911 3.0884 1.8122-.4027 2.1482-1.9469 1.4095-4.4312z"
				/>
				<path
					d="m93.8964 21.3195c-.6041-1.8803-1.6789-5.0693-2.6191-7.9563-.4693-1.5442-.7047-1.9129-1.3428-2.4162-.4027-.3361-.57-.8735-.57-2.34963 0-2.61905.9727-3.2231 5.6053-3.2231 4.0951 0 4.8339.8054 4.8339 2.88702 0 1.20811-.1333 1.77961-.7388 2.34961-.4353.4367-.5034.8054-.3346 1.6789.3346 1.5442.7714 3.2897 1.1415 4.6666.2012.9061.2692 1.2422.6052 1.2422.436 0 .538-.5034.84-1.4095.503-1.5782.571-1.713 1.141-3.8612.302-1.1415.034-1.7455-.604-2.3496-.334-.4027-.638-.94012-.638-2.21634 0-2.04756.503-2.92107 4.8-2.92107 3.056 0 3.324.87351 3.324 2.71972 0 1.44351-.369 2.04759-.772 2.51839-.571.6381-.738.9401-1.175 2.1142-1.275 3.3238-1.88 5.1033-3.189 8.7617-.84 2.283-1.108 2.8204-3.29 2.8204h-2.7182c-2.619 0-3.3237-.2354-4.2965-3.0543z"
				/>
				<path
					d="m109.67 15.4123c0-5.57268 3.02-10.50727 9.6-10.50727 5.035 0 8.56 2.75229 8.56 8.45967 0 2.6517-.571 3.0544-3.894 3.0544h-6.881c-.403 0-.671.1347-.671.604 0 1.0749 2.082 1.7796 4.667 1.7796.83 0 1.508-.0607 2.078-.1569.754-.1274 3.373-.7817 3.804.8587.072.2754.058.5655-.009.8409-.696 2.8811-3.706 4.5334-7.651 4.5334-5.74 0-9.6-3.7931-9.6-9.4665zm12.766-3.9944c-.186-1.46132-1.487-2.7553-3.163-2.67091-2.14.08291-3.024 1.41981-2.884 3.46441.047.7936.419.9179 1.209.9179 4.419 0 4.979-.5848 4.838-1.7114z"
				/>
				<path
					d="m23.0608 15.6803c0-5.5727 3.0203-10.50733 9.5997-10.50733 5.0353 0 8.5604 2.7523 8.5604 8.45973 0 2.6516-.5715 3.0543-3.8938 3.0543h-6.8815c-.4027 0-.6706.1347-.6706.6041 0 1.0748 2.0816 1.7795 4.6666 1.7795.8306 0 1.5086-.0607 2.0786-.1569.7536-.1273 3.3727-.7817 3.8035.8587.0725.2754.0577.5656-.0089.841-.6958 2.8811-3.7057 4.5333-7.6513 4.5333-5.74 0-9.5997-3.7931-9.5997-9.4664zm12.7665-3.9945c-.1865-1.4613-1.4879-2.75526-3.1638-2.67087-2.1394.08291-3.0233 1.41987-2.8841 3.46447.0474.7935.419.9179 1.2096.9179 4.4194 0 4.979-.5848 4.8383-1.7115z"
				/>
			</g>
		</g>
	</svg>
</template>
