<template>
	<svg
		fill="none"
		height="11"
		viewBox="0 0 49 11"
		width="49"
		xmlns="http://www.w3.org/2000/svg"
		xmlns:xlink="http://www.w3.org/1999/xlink"
	>
		<clipPath id="a"><path d="m0 0h49v10.7976h-49z" /></clipPath>
		<g clip-path="url(#a)">
			<path
				d="m45.5614-.00000203c.5517.00817416 1.0187.38112003 1.3967.86595003.0782.100134.2504.142022.3301.043425.7372-.90937502 1.712-.699401 1.7109.255957.0562 1.93881-2.8379 4.76809-3.5435 2.49669-.3101-.99878-1.036-1.67519-1.0682-2.42006-.0291-.671304.5022-1.25166885 1.1745-1.24196203z"
				fill="#ea534a"
			/>
			<g fill="#f3eee8">
				<path
					d="m.371017 9.70829c0-.70655.115971-.86902.451622-1.15818.277921-.23143.312661-.46337.312661-.85726v-2.76849c0-.59109-.03474-.77603-.439871-.78778-.428632-.02299-.69531439-.18545-.69531439-.60233-.01175031-.53286.26617139-.94974.68356439-1.29765.324411-.26668.764281-.45163 1.192921-.45163.57934 0 .93849.31267.94973.77604 0 .27792.05824.32441.1737.32441.16246 0 .23143-.19669.3709-.38214.27793-.43987.77604-.81078 1.51733-.81078 1.57557 0 2.56005 1.2859 2.56005 3.28959s-.85727 3.39432-2.722 3.39432c-.99622-.01175-1.01921-.47512-1.2859-.46337-.26617.02299-.26617.23143-.25493.49811.01175.1737.06948.28967.3474.45162.3939.2202.57935.45163.57935 1.00747 0 .55586-.25493.91496-1.10045.92676-1.12343 0-2.641274-.139-2.641274-1.08871zm4.575483-4.64497c-.15071-.77603-.42863-1.28589-1.05395-1.16992-.5676.10422-.66058.67181-.5676 1.34363.11546.94973.38215 1.2394 1.01922 1.15817.59058-.05773.75304-.53285.60233-1.33239z"
				/>
				<path
					d="m15.3727 6.37222c0-.64883.1272-1.18168.1272-1.88823 0-.3474-.0577-.31266-.4634-.32441-.4286-.02299-.4286-.37091-.4286-.89201 0-.86902.1625-.78778 1.3089-1.39012.753-.40513 1.1699-.70655 1.7028-.70655.2432 0 .4516.11597.4516.45162 0 .46337.1155.66977.6371.66977.6718 0 .9267 0 .9267.56759 0 .32442-.0347.50936-.0577.96149-.0348.66006-.3709.66006-1.0545.66006-.799 0-.6371-.24113-.66.51191 0 .24318-.023.48636-.023.70655 0 .35916.2084.54461.5328.54461.3014 0 .6371-.08123.892-.08123.2202 0 .3474.11597.3357.60233-.0113 1.03096-.6831 1.76102-1.8995 1.76102-1.3436 0-2.3281-.81077-2.3281-2.1544z"
				/>
				<path
					d="m20.1106 7.33374c0-.41688.023-.67181.3474-.94973.2549-.22019.2897-.45162.3244-.96149.0113-.39389.0113-.79953.0113-1.2394 0-.47513 0-1.27415-.0113-1.71453-.0117-.35916-.0695-.42864-.3939-.45163-.3709-.01175-.6258-.13896-.6258-.59058 0-.937474 3.5215-2.347516 3.5215-.532853 0 .312663-.0347.822523-.093 1.725773-.0694.892-.1737 2.22388-.1272 3.0464.023.33566.0695.47513.2897.67182.2432.20844.3704.42863.3704.92674 0 .85727-.2897 1.18168-1.8188 1.18168-1.3553 0-1.7952-.11597-1.7952-1.1122z"
				/>
				<path
					d="m24.0259 5.17929c0-2.01544 1.0422-3.49854 3.3361-3.47504 2.2126.02299 3.2318 1.44785 3.2318 3.47504 0 2.0272-1.0544 3.40556-3.3018 3.40556s-3.2666-1.35537-3.2666-3.40556zm4.1469-.20844c-.3127-.99622-.6141-1.39011-1.2165-1.22765-.5558.13896-.7065.72954-.4746 1.69154.2315.892.6253 1.20466 1.2047 1.0657.6253-.13896.7413-.67181.4864-1.52908z"
				/>
				<path
					d="m32.401 7.35672c-.2085-.64882-.5794-1.74927-.9038-2.74549-.1619-.53286-.2432-.66007-.4633-.83377-.139-.11597-.1967-.30142-.1967-.81077 0-.90376.3356-1.1122 1.9342-1.1122 1.4131 0 1.668.27792 1.668.99623 0 .41688-.046.61408-.2549.81077-.1502.15071-.1737.27792-.1155.57935.1155.53285.2662 1.13518.3939 1.61031.0695.31266.093.42863.209.42863.1502 0 .1854-.1737.2896-.48636.1737-.54461.1972-.5911.3939-1.33239.1042-.39389.0118-.60234-.2084-.81078-.1155-.13896-.2202-.32441-.2202-.76479 0-.70656.1737-1.00798 1.6563-1.00798 1.0544 0 1.1469.30142 1.1469.9385 0 .49811-.1272.70655-.2662.86901-.1972.22019-.2549.32442-.4056.72955-.4399 1.14693-.6488 1.76102-1.1004 3.02341-.2897.78779-.3822.97324-1.1352.97324h-.938c-.9038 0-1.1469-.08123-1.4826-1.05396z"
				/>
				<path
					d="m37.844 5.31826c0-1.92298 1.0422-3.62576 3.3126-3.62576 1.7375 0 2.9539.94974 2.9539 2.9192 0 .915-.1972 1.05396-1.3436 1.05396h-2.3746c-.139 0-.2314.04649-.2314.20844 0 .3709.7183.61408 1.6103.61408.2866 0 .5206-.02094.7172-.05415.2601-.04394 1.1638-.26975 1.3125.29631.025.09503.0199.19516-.0031.29018-.2401.99419-1.2787 1.56433-2.6402 1.56433-1.9807 0-3.3126-1.30888-3.3126-3.26659zm4.4054-1.37837c-.0644-.50425-.5135-.95076-1.0918-.92164-.7382.02861-1.0432.48994-.9952 1.19547.0163.27384.1446.31675.4174.31675 1.525 0 1.7181-.2018 1.6696-.59058z"
				/>
				<path
					d="m7.95776 5.41078c0-1.92297 1.04221-3.62575 3.31254-3.62575 1.7376 0 2.954.94974 2.954 2.9192 0 .915-.1972 1.05396-1.3436 1.05396h-2.3746c-.139 0-.2315.04649-.2315.20844 0 .3709.7183.61408 1.6103.61408.2866 0 .5206-.02095.7173-.05415.2601-.04394 1.1638-.26975 1.3125.29631.025.09503.0199.19516-.0031.29018-.2401.99419-1.2787 1.56433-2.6402 1.56433-1.98074 0-3.31261-1.30888-3.31261-3.2666zm4.40534-1.37836c-.0643-.50425-.5134-.95076-1.0917-.92164-.7383.02861-1.0433.48994-.9952 1.19547.0163.27384.1445.31675.4174.31675 1.5249 0 1.7181-.2018 1.6695-.59058z"
				/>
			</g>
		</g>
	</svg>
</template>
