<template>
	<div class="main-dropdown flex">
		<div class="main-dropdown__sectors">
			<ul class="py-8">
				<li
					v-for="(sector, sectorIndex) in sectors"
					:key="sector.id"
					class="main-dropdown__sectors--item font-body-s font-medium color-primary px-4 flex align-items-center justify-content-between"
					:class="{ 'main-dropdown__sectors--item--selected': selectedSectorIndex === sectorIndex }"
					@mouseenter="handleSectorHover(sector, sectorIndex)"
				>
					{{ sector.title }}
					<CaIcon
						name="chevron-right"
						color="primary"
						:size="16"
						class="main-dropdown__sectors--icon"
						:class="{ 'active-icon': selectedSectorIndex === sectorIndex }"
					/>
				</li>
			</ul>
		</div>

		<div
			class="main-dropdown__list"
			@mouseenter="sectorHovered = true, keepSectorSelected"
			@mouseleave="sectorHovered = false, clearSectorSelection"
		>
			<ul
				class="main-dropdown__list--grid px-8 flex"
				:class="{ 'justify-content-between': filteredCategories.length === 3, 'row': filteredCategories.length > 4 }"
			>
				<li
					v-for="category in filteredCategories"
					:key="category.id"
					class="main-dropdown__list--category col-3 font-medium color-neutral"
				>
					<span>{{ category.title }}</span>
					<ul class="pt-3">
						<li
							v-for="subcategory in subcategories(category)"
							:key="subcategory.id"
							class="main-dropdown__list--subcategory py-3"
						>
							<CaLink
								full-width
								:href="subcategory.path"
								@click="handleMenu(subcategory)"
							>
								{{ subcategory.title }}
							</CaLink>
						</li>
					</ul>
					<CaLink
						class="main-dropdown__list--category__link my-4"
						icon="arrow-right"
						icon-placement="right"
						color="primary"
						:href="category.path"
						@click="handleMenu(category)"
					>
						Acessar o departamento
					</CaLink>
				</li>
			</ul>
		</div>
	</div>
</template>

<script>
export default {
	name: 'MainDropdown',
	props: {
		sectors: {
			type: Array,
			default: () => [],
		},
		hoveredDepartment: {
			type: Object,
			default: () => {},
		},
	},
	data() {
		return {
			selectedSectorIndex: null,
			selectedSector: null,
		};
	},
	computed: {
		filteredCategories() {
			return this.selectedSector?.children?.filter(category => category.children?.length > 0).slice(0, 8) || [];
		},
		numberOfSubcategories() {
			return this.filteredCategories?.length > 4 ? 3 : 10;
		},
	},
	watch: {
		hoveredDepartment: {
			handler: 'handleDepartamentHover',
			immediate: true,
		},
	},
	methods: {
		handleMenu(item) {
			this.$emit('handle-menu', item);
		},
		handleDepartamentHover(hoveredDepartment) {
			if (hoveredDepartment) {
				this.selectedSectorIndex = 0;
				this.selectedSector = hoveredDepartment?.children?.filter(sector => sector?.children?.length !== 0)[0] || null;
			}
			else {
				this.selectedSectorIndex = null;
				this.selectedSector = null;
			}
		},
		handleSectorHover(sector, index) {
			this.selectedSectorIndex = index;
			this.selectedSector = sector;
		},
		keepSectorSelected(sector) {
			this.sectorHovered = true;
			this.selectedSector = sector;
		},
		clearSectorSelection() {
			this.sectorHovered = false;
			this.selectedSectorIndex = null;
			this.selectedSector = null;
		},
		subcategories(category) {
			return category?.children.slice(0, this.numberOfSubcategories) || [];
		},
	},
};
</script>

<style lang="scss" scoped>
.main-dropdown {
  @media (max-width: $large-breakpoint) {
    font-size: fontSize(body-s);
  }

  &__sectors,
  &__list {
    margin-top: 0.6rem;
  }

  &__sectors {
    height: 53rem;
    margin-left: 20rem;
    margin-top: 0.6rem;
    background-color: color(white);
    border-top-left-radius: rounded(l);
    border-bottom-left-radius: rounded(l);
    box-shadow: shadow(l);

    @media (max-width: $huge-desktop-breakpoint) {
      margin-left: 10rem;
    }

    @media (max-width: $extra-large-desktop-breakpoint) {
      margin-left: 5rem;
    }

    @media (max-width: $large-desktop-breakpoint) {
      margin-left: space(8);
    }

    &--item {
      width: 30rem;
      height: 5.6rem;

      @media (max-width: $large-breakpoint) {
        font-size: fontSize(body-s);
        width: 24rem;
      }

      &:hover, &--selected {
        background-color: color(neutral, lightest);
      }
    }

    .active-icon {
      transform: translateX(0.8rem);
      transition: all 0.3s ease-in-out;
    }
  }

  &__list {
    width: 100%;
    margin-right: 20rem;
    background-color: color(neutral, lightest);
    border-top-right-radius: rounded(l);
		border-bottom-right-radius: rounded(l);
    box-shadow: 20px 0 24px rgba(134, 126, 117, 0.12), 20px 16px 28px rgba(134, 126, 117, 0.12);

    @media (max-width: $huge-desktop-breakpoint) {
      margin-right: 10rem;
    }

    @media (max-width: $extra-large-desktop-breakpoint) {
      margin-right: 5rem;
    }

    @media (max-width: $large-desktop-breakpoint) {
      margin-right: space(8);
    }

    &--grid {
      flex-wrap: wrap;

      @media (max-width: $large-desktop-breakpoint) {
        padding: 0 space(6);
      }

      @media (max-width: $desktop-breakpoint) {
        padding: 0 space(3);
      }
    }

    &--category {
      padding-top: space(8);

      @media (max-width: $large-breakpoint) {
        font-size: fontSize(body-xs);
      }

      &:deep(.menu__list-item--link) {
        padding: 0;

        .link__icon {
          @media (max-width: $large-breakpoint) {
            height: space(5);
          }
        }
      }

      &__link {
        padding: 0;
        &:hover {
          color: color(primary);
        }
      }
    }

    &--subcategory {
      &:deep() {
        .link {
          padding: 0;

          &:hover {
            color: color(primary);
          }
        }

        .link__text {
          white-space: normal;
        }
      }
    }
  }
}
</style>
