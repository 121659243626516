<template>
	<nav class="menu">
		<ul class="menu__list flex justify-content-center relative">
			<li
				v-for="(department, index) in items"
				:key="index"
				class="menu__list-item"
				:class="{ relative: department.menu_type === 'list' }"
				@mouseenter="handleDepartamentHover(department)"
				@mouseleave="handleDepartamentLeave"
			>
				<CaLink
					class="menu__list-item--link"
					:class="{ 'no-pointer': !department.path }"
					full-width
					:href="department.path"
					@click="handleMenu(department)"
				>
					<div class="flex align-items-center">
						<div :class="{ 'pr-3': department.children.length }">
							<span class="font-body">{{ department.title }}</span>
							<CaTag
								v-if="department.tag"
								color="positive"
								size="small"
							>
								{{ department.tag }}
							</CaTag>
						</div>
						<CaIcon
              v-if="department.children.length"
              name="chevron-bottom"
              color="neutral"
              :size="16"
              :class="{ 'rotate-180': hoveredDepartment === department }"
            />
					</div>
					<hr class="divider">
				</CaLink>

				<!-- main dropdown -->
				<div
					v-if="department.children.length && department.menu_type !== 'list'"
					class="menu-dropdown"
					:class="{ show: hoveredDepartment === department }"
				>
					<WsHeaderDesktopMenuMain
						:sectors="sectors"
						:hovered-department="hoveredDepartment"
						@handle-menu="handleMenu"
					/>
				</div>

				<!-- type list dropdown -->
				<div
					v-if="department.children.length && department.menu_type === 'list'"
					class="menu-dropdown-type-list"
					:class="{ show: hoveredDepartment === department }"
				>
					<WsHeaderDesktopMenuTypeList
						:categories="department.children"
						@handle-menu="handleMenu"
					/>
				</div>
			</li>
		</ul>
	</nav>
</template>

<script setup>
import { slugify } from '@petlove/frontend-utilities-utils';
import { useUsersStore } from '@petlove/lion/store/users';

const { $gtm } = useNuxtApp()

const hoveredDepartment = ref(null);
const isDropdownVisible = ref(false);
const hoverDelay = ref(null);

const usersStore = useUsersStore();

const { fetchPointer } = usePointer();

const { data: menu } = await fetchPointer();

const sectors = computed(() => {
	return (
		hoveredDepartment.value?.children?.filter(
			sector => sector?.children?.length !== 0,
		) || []
	);
});

const items = computed(() => {
	return (
		menu?.value?.main?.map((item) => {
			if (item?.title === 'Clube de descontos' && usersStore.isClubMember) {
				return { ...item, path: '/clube-de-descontos/gestao' };
			}
			return item;
		}) || []
	);
});

const handleMenu = (item) => {
	sendEvents(item);
	if (item?.path) window.open(item.path, '_self');
};

const dispatchGtmEventForItem = (item, label) => {
	$gtm.dispatchEvent({
		event_category: 'click',
		event_action: `menu:item:${slugify(item?.title)}`,
		event_label: label,
	});
};

const sendEvents = (item) => {
	if (!items.value) return;

	items.value.forEach((menuItem) => {
		if (menuItem?.type === 'department') {
			handleDepartmentEvent(menuItem, item);
		}

		menuItem?.children?.forEach((sector) => {
			sector?.children?.forEach((category) => {
				if (item?.type === 'category') {
					handleCategoryEvent(menuItem, sector, category, item);
				}

				category?.children?.forEach((subcategory) => {
					if (item?.type === 'subcategory') {
						handleSubcategoryEvent(
							menuItem,
							sector,
							category,
							subcategory,
							item,
						);
					}
				});
			});
		});
	});
};

const handleDepartmentEvent = (menuItem, item) => {
	if (menuItem?.path === item?.path) {
		const params = createParams(null, menuItem?.title);
		dispatchGtmEventForItem(item, params.section);
	}
};

const handleCategoryEvent = (menuItem, sector, category, item) => {
	if (category?.path === item?.path) {
		if (menuItem?.menu_type === 'list') {
			const params = createParams(sector?.title, menuItem?.title);
			dispatchGtmEventForItem(item, params.departament);
		}
		if (menuItem?.menu_type === 'nested') {
			const params = createParams(sector?.title, menuItem?.title);
			dispatchGtmEventForItem(item, `${params.section}:${params.departament}`);
		}
	}
};

const handleSubcategoryEvent = (
	menuItem,
	sector,
	category,
	subcategory,
	item,
) => {
	if (subcategory?.path === item?.path) {
		const params = createParams(sector?.title, menuItem?.title, category?.title);
		dispatchGtmEventForItem(
			item,
			`${params.section}:${params.departament}:${params.category}`,
		);
	}
};

const createParams = (
	sectorTitle,
	departamentTitle = null,
	categoryTitle = null,
	subCategoryTitle = null,
) => {
	return {
		section: sectorTitle ? slugify(sectorTitle) : 'comprar-produtos',
		departament: departamentTitle ? slugify(departamentTitle) : null,
		category: categoryTitle ? slugify(categoryTitle) : null,
		subCategory: subCategoryTitle ? slugify(subCategoryTitle) : null,
	};
};

const handleDepartamentHover = (department) => {
	hoverDelay.value = setTimeout(function () {
		hoveredDepartment.value = department;
		isDropdownVisible.value = true;
	}, 300);
};

const handleDepartamentLeave = () => {
	clearTimeout(hoverDelay.value);
	hoverDelay.value = null;
	hoveredDepartment.value = null;
	isDropdownVisible.value = false;
};
</script>

<style lang="scss" scoped>
.menu {
  height: 5.2rem;
  width: 100%;
  background-color: color(white);
  border-bottom: border(s) solid color(neutral, light);

  &__list {
    &-item {
      height: 5.3rem;

      &--link:hover {
        span {
          color: color(primary);
        }
      }

      &:hover > .menu__list-item--link .divider {
        animation: fadeIn 0.2s linear;
        display: block;
        opacity: 1;
        visibility: visible;
        will-change: opacity;
      }

      @media (max-width: $large-desktop-breakpoint) {
        font-size: fontSize(body-s);
      }

      .no-pointer {
        cursor: default;
      }

      &:deep(.menu__list-item--link) {
        color: color(neutral);
        font-weight: 400;
        padding: 1.4rem;

        @media (max-width: $extra-large-desktop-breakpoint) {
          padding-right: space(4);
          padding-left: space(4);
        }

        @media (max-width: $large-breakpoint) {
          padding-right: 0.6rem;
          padding-left: 0.6rem;
        }

        &:hover {
          text-decoration: none;
        }

        .divider {
          border-bottom: 0.3rem solid color(primary);
          margin: 1rem 0 0 0;
          opacity: 0;
          display: none;
          visibility: hidden;
          will-change: opacity;
        }

        .tag {
          height: 2.2rem;
        }
      }
    }
  }
}

.menu-dropdown,
.menu-dropdown-type-list {
  position: absolute;
  top: 5rem;
  z-index: 50;
  opacity: 0;
  display: none;
  visibility: hidden;
  will-change: opacity;
}

.menu-dropdown {
  left: 0;
  width: 100%;
}

.menu-dropdown-type-list {
  right: 0;
}

.menu-dropdown.show,
.menu-dropdown-type-list.show {
  animation: fadeIn 0.2s linear;
  display: block;
  opacity: 1;
  visibility: visible;
  will-change: opacity;
}

.rotate-180 {
  transition: transform 0.3s ease-in-out;
  transform: rotate(-180deg);
}
</style>
