<template>
	<div class="search">
		<div>
			<form
				autocomplete="off"
				@submit.prevent="submitSearch()"
			>
				<input
					ref="search"
					v-model="inputSearch"
					type="search"
					name="inputSearch"
					:title="`Buscar por ${inputSearch}`"
					class="search__input-petlove"
					:placeholder="placeholder"
					@keyup="onTyping($event)"
					@focus="handleFocus()"
					@blur="blurInput()"
				>
				<CaIcon
					name="search"
					class="search__icon-petlove"
					@click.native="submitSearch()"
				/>
			</form>
		</div>
		<div
			v-if="suggestions && inputFocused"
			class="search__autocomplete mt-4 relative"
		>
			<ul v-if="suggestions">
				<li
					v-for="(item, index) in suggestions"
					:key="`sugestion-${index}`"
					:class="{
						'search__autocomplete-sugestion--focused':
							focusedItemIndex === index,
					}"
				>
					<a
						:href="`/busca?q=${item.term}`"
						class="search__autocomplete-sugestion"
						target="_self"
						:title="`Buscar por ${item.term}`"
					>
						{{ item.term }}
					</a>
				</li>
			</ul>
			<ul v-if="products.length">
				<li
					v-for="(item, index) in products"
					:key="`product-${index}`"
				>
					<a
						:href="item.path"
						class="search__autocomplete-products"
						target="_self"
						title="Ver produto"
					>
						<img
							:key="item.sku"
							loading="lazy"
							:src="item.image"
							:alt="item.name"
							class="search__autocomplete-products--image"
						>
						<div class="search__autocomplete-products--info">
							<div>{{ item.name }}</div>
							<div class="search__autocomplete-products--price">
								{{ formatPrice(item.prices.original) }}
							</div>
						</div>
					</a>
				</li>
			</ul>
		</div>
	</div>
</template>

<script>
import { mapActions, mapGetters } from 'pinia';
import { priceFormatter } from '@petlove/frontend-utilities-utils';
import { getCookie, setCookie } from '@petlove/lion/libs/cookies';
import { useSearchStore } from '@petlove/lion/store/search';

export default {
	name: 'InputSearch',
	props: {
		placeholder: {
			type: String,
			default: 'O que seu pet precisa?',
		},
		textValue: {
			type: String,
			default: '',
		},
	},
	data() {
		return {
			inputSearch: this.textValue,
			inputFocused: false,
			debounce: null,
			focusedItemIndex: -1,
		};
	},
	watch: {
		placeholder() {
			this.inputSearch = '';
			this.focusedItemIndex = -1;
		},
		products() {
			this.focusedItemIndex = -1;
		},
	},
	computed: {
		...mapGetters(useSearchStore, ['products', 'suggestions']),
	},
	methods: {
		...mapActions(useSearchStore, ['fetchResults', 'setResults']),
		submitSearch() {
			const searchTerm = encodeURIComponent(this.inputSearch);
			sessionStorage.removeItem('search');
			window.location.href = `/busca?q=${searchTerm}`;
			setCookie('PL_search', searchTerm);
		},
		blurInput() {
			setTimeout(() => {
				this.inputFocused = false;
				this.setResults({});
				this.inputSearch = '';
				this.$refs.search.blur();
				sessionStorage.removeItem('search');
			}, 200);
		},
		onTyping(event) {
			const inputValue = this.inputSearch;
			// if keypress is arrow up or down and has autocomplete items
			if (
				this.products?.length !== 0
				&& (event.keyCode === 38 || event.keyCode === 40)
			) {
				return this.browseAutocomplete(event.keyCode);
			}

			this.fetchResults(inputValue);
		},
		browseAutocomplete(keyCode) {
			const itemsQuantity = this.suggestions?.length - 1;

			this.focusedItemIndex
        = keyCode === 40 ? this.focusedItemIndex + 1 : this.focusedItemIndex - 1;

			if (this.focusedItemIndex > itemsQuantity) this.focusedItemIndex = 0;
			else if (this.focusedItemIndex < 0) this.focusedItemIndex = itemsQuantity;

			this.inputSearch = this.suggestions[this.focusedItemIndex].term;
		},
		handleFocus() {
			this.inputFocused = true;
			if (!getCookie('PL_score') && typeof window?.DY?.API === 'function') {
				window.DY.API('event', {
					name: 'click-at-menu-autocomplete',
				});
			}
		},
		formatPrice(price) {
			return priceFormatter(price);
		},
	},
};
</script>

<style lang="scss" scoped>
%basicInput {
  letter-spacing: 0.03rem;
  padding: 1.6rem;
  appearance: none;
  -webkit-appearance: none;
  border-radius: 0.4rem;
  width: 100%;
  min-width: 22rem;
  height: 4.8rem;
  font-size: 14px;
  transition: all 0.15s;
  &::placeholder {
    font-size: 14px;
  }
}

%basicIcon {
  top: 1.2rem;
  position: absolute;
  right: 1.6rem;
  cursor: pointer;
  transition: all 0.2s ease-in;
}
 %basicList {
  display: flex;
  flex: 1;
  background-color: color(white);
  padding: 1rem 2rem;
  transition: all 0.1s ease-in;
  cursor: pointer;
 }

.search {
    display: block;
    width: 100%;
    text-align: left;
    position: relative;
    &__input-petlove {
      @extend %basicInput;
      background-color: color(white);
      color: color(neutral);
      border: 1px solid color(neutral, medium);
      &:focus {
        outline: 0;
        border: 1px solid color(primary);
      }
    }
    &__icon-petlove {
      @extend %basicIcon;
      fill: color(neutral, medium);
      &:focus,
      &:active,
      &:hover {
        fill: color(primary);
      }
    }

    &__autocomplete {
      border-radius: 0.4rem;
      background-color: color(white);
      padding: 1rem 0 1rem 0;
      position: absolute;
      z-index: 10;
      box-shadow: 0 4px 14px rgba(0,0,0,.2), 0 4px 8px rgba(0,0,0,.04);
      text-align: left;
      max-height: 90dvh;
      overflow-y: auto;
      overscroll-behavior: contain;

      &-sugestion {
        @extend %basicList;
        &:active {
          background-color: color(neutral, light);
        }
        &:hover {
          color: color(primary);
        }
      }

      &-products {
        @extend %basicList;
        border-top: 1px solid color(neutral, light);

        &--image {
          float: left;
          width: 48px;
          height: 48px;
          object-fit: fill;
        }
        &--info {
          padding-left: 2rem;
        }
        &--price {
          color: color(primary);
          margin-top: 0.3rem;
        }
        &:hover {
          color: color(primary);
        }
      }
    }
  }
</style>
