<template>
	<ul class="type-list__category p-6">
		<li
			v-for="category in categories[0].children"
			:key="category.id"
			class="font-body"
		>
			<CaLink
				class="type-list__category--link"
				full-width
				:href="category.path"
				@click="handleMenu(category)"
			>
				{{ category.title }}
			</CaLink>
		</li>
	</ul>
</template>

<script>
export default {
	name: 'TypeListDropdown',
	props: {
		categories: {
			type: Array,
			default: () => [],
		},
	},
	methods: {
		handleMenu(category) {
			this.$emit('handle-menu', category);
		},
	},
};
</script>

<style lang="scss" scoped>
.type-list__category {
    width: 100%;
    background-color: color(neutral, lightest);
    border-radius: rounded(l);
    box-shadow: shadow(l);
    margin-top: 0.6rem;

    &:deep(.type-list__category--link) {
      color: color(neutral, dark);
      font-weight: 400;

      @media (max-width: $large-desktop-breakpoint) {
        font-size: fontSize(body-s);
      }

      @media (max-width: $large-breakpoint) {
        font-size: fontSize(body-xs);
      }
    }
  }
</style>
